.jumbo {

	position: relative;
	padding: 0;
	background-color: theme(highlight, base);

	.carousel {
		position: relative;
		max-width: 1400px;
		margin: 0 auto;
		border-bottom: 5px solid $white;
	}

		.item {
    		overflow: hidden;
			img {
				background-repeat: no-repeat;
				background-size: cover; 
				background-position: center bottom;
			}
		}

	@include media-breakpoint-up(lg) {
		background-color: transparent;
		.carousel { border: none }
		.item { height: 460px; }
	}

	@media (min-width: 1399px) {
		.carousel-inner {
 			border-left: 5px solid $white;
			border-right: 5px solid $white;			
			box-shadow: 0 0 45px 0 rgba(0,0,0,0.8);
		}		
	}

}

	.jumbo .carousel-caption {

		text-shadow: 1px 1px 1px rgba(0,0,0,0.8);
	    background-color: theme(primary, base);
    	color: $white;

		br { display: none; }

		@include media-breakpoint-up(md) {
	        position: absolute;
	        z-index: 10;
	        bottom: 0;
	        width: 100%;
	        background-color: rgba(theme(primary, dark), 0.7);
	        color: $white;
	        text-shadow: 0 1px 2px rgba(0,0,0,0.7);
		    .caption_title { font-size: 1.6rem; letter-spacing: -0.025em }
	        .caption_text { font-size: 1rem }
		}

	    @include media-breakpoint-up(lg) {
    		right: -60px;
    		top: 38.5%;
    		bottom: auto;
	        transform: skew(-20deg);
    		width: auto;
	        padding: 1em 100px 1em 2em;
	        text-align: right;
	        background-color: rgba(theme(secondary, base), 0.7);
	        br { display: block; }
		    .caption_title {
		    	transform: skew(5deg);
		    	font-size: 2.25rem;
		    }
	    }

	    @include media-breakpoint-up(xl) { padding-right: 10%; }
	    @media (min-width: 1300px) { padding-right: 12.5%; }
	    @media (min-width: 1400px) { padding-right: 15%; }

	}

		.item_01 img {
			background-image: url("/_/images/slides/01-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/01-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/01-lg.jpg") }
		}

		.item_02 img {
			background-image: url("/_/images/slides/02-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/02-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/02-lg.jpg") }
		}

		.item_03 img {
			background-image: url("/_/images/slides/03-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/03-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/03-lg.jpg") }
		}

		.item_04 img {
			background-image: url("/_/images/slides/04-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/04-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/04-lg.jpg") }
		}

		.item_05 img {
			background-image: url("/_/images/slides/05-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/05-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/05-lg.jpg") }
		}

		.item_06 img {
			background-image: url("/_/images/slides/06-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/06-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/06-lg.jpg") }
		}

		.item_07 img {
			background-image: url("/_/images/slides/07-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/07-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/07-lg.jpg") }
		}

		.item_08 img {
			background-image: url("/_/images/slides/08-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/08-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/08-lg.jpg") }
		}

		.item_09 img {
			background-image: url("/_/images/slides/09-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/09-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/09-lg.jpg") }
		}

		.item_10 img {
			background-image: url("/_/images/slides/10-sm.jpg");
			@include media-breakpoint-up(md) { background-image: url("/_/images/slides/10-md.jpg") }
			@include media-breakpoint-up(lg) { background-image: url("/_/images/slides/10-lg.jpg") }
		}


.jumbo_conversion {

	padding-top: 1em;
	padding-bottom: 1em;
	font-size: 1.1rem;
	line-height: 1em;
	text-align: center;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.8);
	color: $white;

	.conversion_title {
		font-weight: 800;
		line-height: 1em;
		text-transform: uppercase;
		small { display: block; }
	}

	.phone {
		font-size: 1.8rem;
		font-weight: 800;
		line-height: 1em;
		color: $white;
	}

	.btn {
		@include btn-reg(theme(primary, light), $white, 7.5%);
		padding: 1em;
		font-size: 0.9rem;
		font-weight: 800;
		text-transform: uppercase;
	}

	.conversion_button { 
		margin-bottom: 0;
		white-space: nowrap;
		img {
			position: relative;
			vertical-align: top;
			display: inline-block;
			width: 2em;
		}
	}

	@include media-breakpoint-up(lg) {
		@include make-col(5);
		margin-top: -370px;
		.conversion_wrapper {
			padding: 2em 1em;
			background-color: rgba(theme(highlight, dark), 0.9);
		}
		p { margin-bottom: 0.5em }
		.conversion_title { font-size: 1.3rem; }
		.phone { font-size: 2.3rem }
		p.conversion_button { margin: 1em 0 0 }
	}

	@include media-breakpoint-up(xl) {
		@include make-col(4);
	}

}