.banner_branding {

	.branding_logo {
		display: block;
		margin: 0 auto 1em;
		max-width: 360px;
		line-height: 1em;
		text-align: center;
		color: $color-text;
		text-decoration: none;
	}

		.branding_slogan {
			display: block;
			margin: 5px 0 0;
			font-size: 14px;
			font-weight: 800;
			line-height: 1em;
			letter-spacing: -0.0275em;
			text-transform: uppercase;
		}

	@include media-breakpoint-up(sm) {
		.branding_logo { text-align: right }
		.branding_slogan { margin: -5px 0 0 }		
	}

	@include media-breakpoint-up(md) {
		.branding_logo { margin: 0 }
	}

}

