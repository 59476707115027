.nav_top {
  *zoom: 1; }
  .nav_top:before, .nav_top:after {
    content: " ";
    display: table; }
  .nav_top:after {
    clear: both; }

.roll_item, .form_quote, .coverage, .widget {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #333; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.block {
  padding: 1em 0; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.main_container {
  margin: 0 0 1em;
  box-sizing: border-box; }
  @media (min-width: 576px) {
    .main_container [class*="col"] {
      padding: 0.5em; }
    .main_container .col-sm-12 {
      float: left;
      width: 100%; }
    .main_container .col-sm-11 {
      float: left;
      width: 91.66667%; }
    .main_container .col-sm-10 {
      float: left;
      width: 83.33333%; }
    .main_container .col-sm-9 {
      float: left;
      width: 75%; }
    .main_container .col-sm-8 {
      float: left;
      width: 66.66667%; }
    .main_container .col-sm-7 {
      float: left;
      width: 58.33333%; }
    .main_container .col-sm-6 {
      float: left;
      width: 50%; }
    .main_container .col-sm-5 {
      float: left;
      width: 41.66667%; }
    .main_container .col-sm-4 {
      float: left;
      width: 33.33333%; }
    .main_container .col-sm-3 {
      float: left;
      width: 25%; }
    .main_container .col-sm-2 {
      float: left;
      width: 16.66667%; }
    .main_container .col-sm-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .main_container .col-md-break {
      float: none;
      width: 100%; }
    .main_container .col-md-12 {
      float: left;
      width: 100%; }
    .main_container .col-md-11 {
      float: left;
      width: 91.66667%; }
    .main_container .col-md-10 {
      float: left;
      width: 83.33333%; }
    .main_container .col-md-9 {
      float: left;
      width: 75%; }
    .main_container .col-md-8 {
      float: left;
      width: 66.66667%; }
    .main_container .col-md-7 {
      float: left;
      width: 58.33333%; }
    .main_container .col-md-6 {
      float: left;
      width: 50%; }
    .main_container .col-md-5 {
      float: left;
      width: 41.66667%; }
    .main_container .col-md-4 {
      float: left;
      width: 33.33333%; }
    .main_container .col-md-3 {
      float: left;
      width: 25%; }
    .main_container .col-md-2 {
      float: left;
      width: 16.66667%; }
    .main_container .col-md-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 992px) {
    .main_container .col-lg-break {
      float: none;
      width: 100%; }
    .main_container .col-lg-12 {
      float: left;
      width: 100%; }
    .main_container .col-lg-11 {
      float: left;
      width: 91.66667%; }
    .main_container .col-lg-10 {
      float: left;
      width: 83.33333%; }
    .main_container .col-lg-9 {
      float: left;
      width: 75%; }
    .main_container .col-lg-8 {
      float: left;
      width: 66.66667%; }
    .main_container .col-lg-7 {
      float: left;
      width: 58.33333%; }
    .main_container .col-lg-6 {
      float: left;
      width: 50%; }
    .main_container .col-lg-5 {
      float: left;
      width: 41.66667%; }
    .main_container .col-lg-4 {
      float: left;
      width: 33.33333%; }
    .main_container .col-lg-3 {
      float: left;
      width: 25%; }
    .main_container .col-lg-2 {
      float: left;
      width: 16.66667%; }
    .main_container .col-lg-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 1200px) {
    .main_container .col-xl-break {
      float: none;
      width: 100%; }
    .main_container .col-xl-12 {
      float: left;
      width: 100%; }
    .main_container .col-xl-11 {
      float: left;
      width: 91.66667%; }
    .main_container .col-xl-10 {
      float: left;
      width: 83.33333%; }
    .main_container .col-xl-9 {
      float: left;
      width: 75%; }
    .main_container .col-xl-8 {
      float: left;
      width: 66.66667%; }
    .main_container .col-xl-7 {
      float: left;
      width: 58.33333%; }
    .main_container .col-xl-6 {
      float: left;
      width: 50%; }
    .main_container .col-xl-5 {
      float: left;
      width: 41.66667%; }
    .main_container .col-xl-4 {
      float: left;
      width: 33.33333%; }
    .main_container .col-xl-3 {
      float: left;
      width: 25%; }
    .main_container .col-xl-2 {
      float: left;
      width: 16.66667%; }
    .main_container .col-xl-1 {
      float: left;
      width: 8.33333%; } }

@media (min-width: 768px) {
  .banner .row {
    align-items: center; }
  .banner .banner_branding {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .banner .banner_contact {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 992px) {
  .banner.has_header_menu .row {
    align-items: start; }
  .banner.has_header_menu .banner_branding {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-top: 40px;
    padding-bottom: 40px; }
  .banner.has_header_menu .banner_contact {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .banner.has_header_menu .banner_menu {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin: -70px 0 0 auto; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 576px) {
  .site_info .row {
    align-items: center; }
  .site_info .wrap {
    flex: 0 0 50%;
    max-width: 50%; } }

html {
  font-size: 16px;
  line-height: 1.6em;
  -webkit-text-size-adjust: 100%; }

body {
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h1, h2, h3, h4, h5, h6 {
  margin: 2rem 0 1rem;
  font-weight: 400;
  line-height: 1em; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    display: block; }

h6 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.0125em; }

h5 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.0125em; }

h4 {
  font-size: 1.3rem;
  letter-spacing: -0.015em; }

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.0175em; }

h2 {
  font-size: 1.7rem;
  letter-spacing: -0.02em; }

h1 {
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: -0.02em; }
  h1 small {
    font-weight: 400; }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.2rem; } }
  @media (min-width: 992px) {
    h1 {
      font-size: 2.4rem; } }
  @media (min-width: 1200px) {
    h1 {
      font-size: 2.6rem; } }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }

.b0 {
  font-weight: 400; }

b, strong, .b1 {
  font-weight: 600; }

.bolder, .strong, .b2 {
  font-weight: 800; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 1.15rem;
  border-left: 0.5rem solid; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333; }
  blockquote.bq--primary {
    border-color: #053891; }
  blockquote.bq--secondary {
    border-color: #EF4923; }
  blockquote.bq--highlight {
    border-color: #5BC0DE; }
  blockquote.bq--accent {
    border-color: #efdc09; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ffc107; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #17a2b8; }
  a:hover, a:focus {
    color: #0f6674;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #f8ab9a; }

.phone {
  text-decoration: none;
  cursor: default;
  color: #EF4923; }
  .phone:hover {
    text-decoration: none;
    color: #EF4923;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #333; }
  .phone.mask:hover {
    outline: none;
    color: #333; }

.list_inline li:not(:last-child), .list_unstyled li:not(:last-child), .list_icon li:not(:last-child), .list li:not(:last-child) {
  margin-bottom: 0.25em; }

.list_inline, .list_unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list_inline li {
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0 !important; }

.list_buttons a {
  border-radius: 3px; }

.list_icon {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; }
  .list_icon li {
    padding-left: 2em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

.sa_review_list {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  .sa_review_list li:not(:last-child) {
    margin-bottom: 0.25em; }
  .sa_review_list span {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 1.1rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.015em;
    background-color: #053891;
    color: #fff; }
  @media (min-width: 576px) {
    .sa_review_list li {
      display: inline-block;
      margin: 0 0.5em 0 0 !important; } }

img {
  display: block;
  width: 100%;
  border-style: none; }

.img--caption span {
  display: block;
  margin: 5px 0 0;
  line-height: 1.1em; }

.img--main {
  margin: 0 0 15px;
  max-width: 360px; }

.img--thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 576px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 15px; } }

@media (min-width: 576px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 15px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  max-width: none; }

.img--coupon {
  max-width: 640px; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

.db {
  display: block; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt15 {
  margin-top: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase; }

.txt--cap {
  text-transform: capitalize; }

.txt--lg {
  font-size: 115%; }

.txt--md {
  font-size: inherit; }

.txt--sm {
  font-size: 85%; }

.lh1 {
  line-height: 1.1em; }

.lh125 {
  line-height: 1.25em; }

.txt--left {
  text-align: left; }

.txt--right {
  text-align: right; }

.txt--center {
  text-align: center; }

.txt--wb0 {
  font-weight: 400; }

.txt--wb1 {
  font-weight: 600; }

.txt--wb2 {
  font-weight: 800; }

.color--text {
  color: #333; }

.color--white {
  color: #fff; }

.color--primary {
  color: #053891; }

.color--secondary {
  color: #EF4923; }

.color--highlight {
  color: #5BC0DE; }

.color--link {
  color: #17a2b8; }

.color--review {
  color: #fd7e14; }

.color--success {
  color: #28a745; }

.color--danger {
  color: #dc3545; }

.color--warning {
  color: #ffc107; }

.color--info {
  color: #6610f2; }

.bg--text {
  background-color: #333; }

.bg--white {
  background-color: #fff; }

.bg--primary {
  background-color: #053891; }

.bg--secondary {
  background-color: #EF4923; }

.bg--highlight {
  background-color: #5BC0DE; }

.bg--link {
  background-color: #17a2b8; }

.bg--review {
  background-color: #fd7e14; }

.bg--success {
  background-color: #28a745; }

.bg--danger {
  background-color: #dc3545; }

.bg--warning {
  background-color: #ffc107; }

.bg--info {
  background-color: #6610f2; }

.gdfancybg--text {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #333; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fff; }

.gdfancybg--primary {
  background: #053891;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #053891; }

.gdfancybg--secondary, .page-title {
  background: #EF4923;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #EF4923; }

.gdfancybg--highlight {
  background: #5BC0DE;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #5BC0DE; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item, .carousel-fade .carousel-inner .active.left, .carousel-fade .carousel-inner .active.right {
  opacity: 0.5; }

.carousel-fade .carousel-inner .active, .carousel-fade .carousel-inner .next.left, .carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next, .carousel-fade .carousel-inner .prev, .carousel-fade .carousel-inner .active.left, .carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0); }

.carousel-fade .carousel-control {
  z-index: 2; }

.carousel-caption {
  position: relative;
  padding: 1em;
  text-align: center;
  text-transform: uppercase; }
  .carousel-caption .caption_title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: -0.025em;
    line-height: 1em; }
  .carousel-caption .caption_text {
    margin: 0;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1em; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .carousel-inner > .item {
    display: none;
    position: relative;
    -webkit-transition: 0.6s ease-in-out left;
    -moz-transition: 0.6s ease-in-out left;
    -ms-transition: 0.6s ease-in-out left;
    -o-transition: 0.6s ease-in-out left;
    transition: 0.6s ease-in-out left; }
    .carousel-inner > .item > img,
    .carousel-inner > .item > a > img {
      display: block;
      max-width: 100%;
      height: auto;
      line-height: 1; }
    @media all and (transform-3d), (-webkit-transform-3d) {
      .carousel-inner > .item {
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        -moz-transition: -moz-transform 0.6s ease-in-out;
        -o-transition: -o-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        -moz-perspective: 1000px;
        perspective: 1000px; }
        .carousel-inner > .item.next, .carousel-inner > .item.active.right {
          -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
          left: 0; }
        .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
          -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
          left: 0; }
        .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          left: 0; } }
  .carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
    display: block; }
  .carousel-inner > .active {
    left: 0; }
  .carousel-inner > .next, .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%; }
  .carousel-inner > .next {
    left: 100%; }
  .carousel-inner > .prev {
    left: -100%; }
  .carousel-inner > .next.left, .carousel-inner > .prev.right {
    left: 0; }
  .carousel-inner > .active.left {
    left: -100%; }
  .carousel-inner > .active.right {
    left: 100%; }

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: transparent; }
  .carousel-control.left {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1); }
  .carousel-control.right {
    left: auto;
    right: 0;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1); }
  .carousel-control:hover, .carousel-control:focus {
    outline: 0;
    color: #fff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90); }
  .carousel-control .icon-prev,
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block; }
  .carousel-control .icon-prev,
  .carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px; }
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px; }
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif; }
  .carousel-control .icon-prev:before {
    content: '\2039'; }
  .carousel-control .icon-next:before {
    content: '\203a'; }

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center; }
  .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: transparent; }
  .carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: #fff; }

@media (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev, .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px; }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px; }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px; }
  .carousel-indicators {
    bottom: 20px; } }

.site_alert > .alert:first-of-type {
  margin-top: 1em; }

.site_alert > .alert:last-of-type {
  margin-bottom: 0; }

.alert {
  border: 1px solid #c4c4c4;
  background-color: white;
  color: #333;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert strong {
    font-weight: 800; }

.alert-primary {
  border: 1px solid #032560;
  background-color: #689dfa;
  color: #333; }
  .alert-primary strong, .alert-primary a {
    color: #021b47; }

.alert-secondary {
  border: 1px solid #d0330f;
  background-color: #fde6e1;
  color: #333; }
  .alert-secondary strong, .alert-secondary a {
    color: #b82d0d; }

.alert-highlight {
  border: 1px solid #31b0d5;
  background-color: white;
  color: #333; }
  .alert-highlight strong, .alert-highlight a {
    color: #28a1c5; }

.alert-accent {
  border: 1px solid #beaf07;
  background-color: #fdf8c7;
  color: #333; }
  .alert-accent strong, .alert-accent a {
    color: #a59806; }

.alert-success {
  border: 1px solid #1e7e34;
  background-color: #afecbd;
  color: #333; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #bd2130;
  background-color: #fae3e5;
  color: #333; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #510bc4;
  background-color: #e2d1fd;
  color: #333; }
  .alert-info strong, .alert-info a {
    color: #4709ac; }

.alert-warning {
  border: 1px solid #d39e00;
  background-color: #fff4d3;
  color: #333; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #117a8b;
  background-color: #a7e9f4;
  color: #333; }
  .alert-link strong, .alert-link a {
    color: #0f6674; }

.announce {
  position: relative;
  z-index: 5000;
  padding: 12px 0;
  border-bottom: 0.25rem solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #021b47;
  color: #fff; }
  .announce p {
    margin-bottom: 0.5em;
    font-weight: 800;
    line-height: 1em;
    text-transform: uppercase; }
    .announce p:last-child {
      margin-bottom: 0; }

[class*="announce_"] {
  text-align: center; }
  @media (min-width: 768px) {
    [class*="announce_"] p {
      float: left;
      margin: 0; } }

@media (min-width: 768px) {
  .announce_2 p {
    width: 50%; } }

@media (min-width: 768px) {
  .announce_3 p {
    width: 33.33333%; } }

@media (min-width: 768px) {
  .announce_4 p {
    width: 25%; } }

.banner {
  background-color: #fff;
  color: #333; }
  @media (min-width: 768px) {
    .banner {
      padding: 2em 0; } }
  @media (min-width: 992px) {
    .banner {
      padding: 3em 0 3.25em; } }
  @media (min-width: 992px) {
    .banner.has_header_menu {
      padding: 2em 0 1.5em; } }

.banner_branding .branding_logo {
  display: block;
  margin: 0 auto 1em;
  max-width: 360px;
  line-height: 1em;
  text-align: center;
  color: #333;
  text-decoration: none; }

.banner_branding .branding_slogan {
  display: block;
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }

@media (min-width: 576px) {
  .banner_branding .branding_logo {
    text-align: right; }
  .banner_branding .branding_slogan {
    margin: -5px 0 0; } }

@media (min-width: 768px) {
  .banner_branding .branding_logo {
    margin: 0; } }

.banner_contact {
  text-align: center; }
  .banner_contact p {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .banner_contact {
      text-align: right; } }

.contact_title {
  display: block;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.04em;
  color: #053891; }

.contact_phone {
  font-size: 2rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.04em;
  color: #EF4923; }
  .contact_phone:hover {
    color: #EF4923;
    outline: none; }

.banner_menu {
  position: relative;
  z-index: 90000;
  padding-top: 1em;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: -0.0125rem;
  text-transform: uppercase; }
  .banner_menu .nav_menu > .menu_item {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    background: linear-gradient(to bottom, #053891 50%, #021b47 50%);
    background-size: 100% 200%;
    background-position: top left; }
    .banner_menu .nav_menu > .menu_item:hover {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      background-position: bottom left; }
    .banner_menu .nav_menu > .menu_item .menu_link,
    .banner_menu .nav_menu > .menu_item .menu_link:hover {
      background-color: transparent; }
  .banner_menu .nav_menu > .menu_item.active > .menu_link {
    background-color: #fff;
    background: #fff;
    color: #053891; }
  @media (min-width: 768px) and (max-width: 991px) {
    .banner_menu.in_header_menu .nav_menu > .menu_item {
      display: block;
      float: left;
      width: 19.2%; }
      .banner_menu.in_header_menu .nav_menu > .menu_item:not(:last-child) {
        margin-right: 1%; } }
  @media (min-width: 992px) {
    .banner_menu.in_header_menu .nav_menu {
      text-align: right; }
    .banner_menu.in_header_menu .nav_menu > .menu_item {
      display: inline-block; } }
  @media (min-width: 768px) {
    .banner_menu.out_header_menu .nav_menu > .menu_item {
      display: block;
      float: left;
      width: 20%; }
    .banner_menu.out_header_menu .nav_menu > .menu_item {
      width: 18%; }
      .banner_menu.out_header_menu .nav_menu > .menu_item > .menu_link {
        padding: 15px 5px; }
    .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(1) {
      width: 10%; }
      .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(1) .menu_link {
        text-indent: -9999px;
        background-image: url("/_/images/icons/home_white.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.5rem; }
    .banner_menu.out_header_menu .nav_menu > .menu_item.active:nth-child(1) .menu_link {
      background-image: url("/_/images/icons/home_active.svg"); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(1) > .menu_link, .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(2) > .menu_link, .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(3) > .menu_link {
      padding: 22px 5px 21px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(1) > .menu_link, .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(2) > .menu_link, .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(3) > .menu_link, .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(4) > .menu_link {
      padding: 22px 5px 21px; } }
  @media (min-width: 1200px) {
    .banner_menu.out_header_menu .nav_menu > .menu_item > .menu_link {
      padding: 22px 5px 21px; }
    .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(5) > .menu_link,
    .banner_menu.out_header_menu .nav_menu > .menu_item:nth-child(6) > .menu_link {
      padding: 15px; } }

.roll_item {
  margin: 0 0 2em;
  padding: 1.25em; }

.roll_header a {
  -webkit-transition: background ease-in-out 300ms;
  -moz-transition: background ease-in-out 300ms;
  -ms-transition: background ease-in-out 300ms;
  -o-transition: background ease-in-out 300ms;
  transition: background ease-in-out 300ms;
  overflow: hidden;
  display: block;
  margin: -1.25em -1.25em 1em;
  padding: 0.5em 1.25em;
  background-color: #EF4923;
  color: #fff;
  text-decoration: none; }
  .roll_header a:hover {
    -webkit-transition: background ease-in-out 300ms;
    -moz-transition: background ease-in-out 300ms;
    -ms-transition: background ease-in-out 300ms;
    -o-transition: background ease-in-out 300ms;
    transition: background ease-in-out 300ms;
    background-color: #f26647; }
    .roll_header a:hover .roll_time {
      -webkit-transition: background ease-in-out 300ms;
      -moz-transition: background ease-in-out 300ms;
      -ms-transition: background ease-in-out 300ms;
      -o-transition: background ease-in-out 300ms;
      transition: background ease-in-out 300ms;
      background-color: transparent; }

.roll_time {
  -webkit-transition: background ease-in-out 300ms;
  -moz-transition: background ease-in-out 300ms;
  -ms-transition: background ease-in-out 300ms;
  -o-transition: background ease-in-out 300ms;
  transition: background ease-in-out 300ms;
  margin: -0.6em -1.6em 0.5em;
  padding: 0.5em 1.5em;
  font-size: 0.8em;
  line-height: 1.2em;
  background-color: rgba(0, 0, 0, 0.3); }
  .roll_time strong {
    display: block;
    text-transform: uppercase; }
  @media (min-width: 576px) {
    .roll_time strong {
      display: inline-block; }
      .roll_time strong:after {
        content: ":"; } }

.roll_title {
  margin: 0;
  font-size: 1.3rem; }

[class*="btn"] {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #959595;
  background-color: #a8a8a8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  border-radius: 3px;
  cursor: pointer; }
  [class*="btn"]:hover {
    background-color: #c8c8c8;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"]:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.list_buttons a, .btn_link {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #0f6674;
  background-color: #138496;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .list_buttons a:hover, .btn_link:hover {
    background-color: #1ab6cf;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_primary {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #010d22;
  background-color: #03235a;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_primary:hover {
    background-color: #0646b6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_secondary {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #b82d0d;
  background-color: #dc3610;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_secondary:hover {
    background-color: #f15d3b;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_highlight {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #28a1c5;
  background-color: #3bb4d8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_highlight:hover {
    background-color: #70c8e2;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_accent {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #a59806;
  background-color: #caba08;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_accent:hover {
    background-color: #f6e41b;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_success {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #19692c;
  background-color: #218838;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_success:hover {
    background-color: #2dbc4e;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_danger {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #a71d2a;
  background-color: #c82333;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_danger:hover {
    background-color: #e04b59;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_info {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #4709ac;
  background-color: #560bd0;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_info:hover {
    background-color: #7528f3;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_review {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #c35a02;
  background-color: #e96b02;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_review:hover {
    background-color: #fd8c2d;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_warning {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #ba8b00;
  background-color: #e0a800;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_warning:hover {
    background-color: #ffc721;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn_close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn_close .close_img {
    width: 0.75rem; }
  .btn_close:hover {
    background-color: #921925; }

.list_buttons a, .btn--sm {
  padding: 0.2em 0.75em;
  font-size: 0.85rem; }

.btn--wide {
  display: block; }

.btn_3d {
  padding: 0.75rem 1rem;
  border-width: 0.05rem;
  border-bottom-width: 0.25rem;
  -webkit-transition: all ease-in-out 150ms;
  -moz-transition: all ease-in-out 150ms;
  -ms-transition: all ease-in-out 150ms;
  -o-transition: all ease-in-out 150ms;
  transition: all ease-in-out 150ms; }
  .btn_3d:hover {
    padding: 0.85rem 1rem;
    border-bottom-width: 0.05rem;
    -webkit-transition: all ease-in-out 150ms;
    -moz-transition: all ease-in-out 150ms;
    -ms-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms; }

[class*="btn_ghost"] {
  border-color: #333;
  background: linear-gradient(to left, #fff 50%, #333 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #333;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  border-width: 2px; }
  [class*="btn_ghost"]:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-default {
  border-color: #17a2b8;
  background: linear-gradient(to left, #fff 50%, #17a2b8 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #17a2b8;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-default:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-primary {
  border-color: #053891;
  background: linear-gradient(to left, #fff 50%, #053891 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #053891;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-primary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-secondary {
  border-color: #EF4923;
  background: linear-gradient(to left, #fff 50%, #EF4923 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #EF4923;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-secondary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-highlight {
  border-color: #5BC0DE;
  background: linear-gradient(to left, #fff 50%, #5BC0DE 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #5BC0DE;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-highlight:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-accent {
  border-color: #efdc09;
  background: linear-gradient(to left, #fff 50%, #efdc09 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #efdc09;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-accent:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-success {
  border-color: #28a745;
  background: linear-gradient(to left, #fff 50%, #28a745 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #28a745;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-success:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-danger {
  border-color: #dc3545;
  background: linear-gradient(to left, #fff 50%, #dc3545 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #dc3545;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-danger:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-info {
  border-color: #6610f2;
  background: linear-gradient(to left, #fff 50%, #6610f2 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #6610f2;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-info:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-review {
  border-color: #fd7e14;
  background: linear-gradient(to left, #fff 50%, #fd7e14 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #fd7e14;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-review:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-warning {
  border-color: #ffc107;
  background: linear-gradient(to left, #fff 50%, #ffc107 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #ffc107;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-warning:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.form {
  font-size: 1em; }
  .form.form_career textarea {
    height: 180px; }

.form_group-wrap {
  box-shadow: none;
  border: 1px solid #ddd;
  margin: 0 0 1.25rem;
  padding: 0 0.25rem 0.75rem; }
  .form_group-wrap p {
    padding: 0 0.75rem; }

.group_title {
  display: block;
  width: 70%;
  padding: 0.5rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  background: #f5f5f5; }

.form_security {
  overflow: hidden;
  margin-bottom: 1em; }

.form_title {
  margin: 0 0.75rem 0.5rem;
  font-size: 1.4rem;
  font-weight: 800; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group {
  padding: 0 0.75em 0.1em; }
  .form_group.form_hp {
    display: none; }

.form_error {
  border: 1px solid #dc3545;
  background-color: #fae3e5; }

.error-message {
  margin: 0 0 0.5em;
  padding: 0 0 0 3px !important;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  color: #d32535; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px; }

@media (max-width: 575px) {
  .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .form_spam .g-recaptcha {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
      position: absolute;
      top: 0;
      left: -18px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fix-lg .form_spam {
    position: relative;
    overflow: hidden;
    height: 80px; }
    .fix-lg .form_spam .g-recaptcha {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      top: 0;
      left: -2px; } }

.form_submit {
  margin-top: 0.25rem; }

@media (min-width: 768px) {
  .form_lr .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr .form_left,
  .form_lr .form_right {
    width: 50%; }
  .form_lr .form_left {
    float: left;
    clear: both; }
  .form_lr .form_right {
    float: right; }
  .form_lr .form_clear {
    clear: both; }
  .form_lr .form_state {
    width: 20%;
    clear: none; }
  .form_lr .form_zip {
    width: 30%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .form_lr--sm .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr--sm .form_left,
  .form_lr--sm .form_right {
    width: 50%; }
  .form_lr--sm .form_left {
    float: left; }
  .form_lr--sm .form_right {
    float: right; }
  .form_lr--sm .form_clear {
    clear: both; } }

.form_newsletter {
  border: none;
  overflow: hidden; }
  @media (min-width: 576px) {
    .form_newsletter .form-email {
      float: left;
      width: 75%;
      padding-left: 0; }
      .form_newsletter .form-email .form-control {
        padding: 11px 6px; }
    .form_newsletter .form-submit {
      float: right;
      width: 25%;
      padding-left: 0; }
    .form_newsletter .btn {
      margin-top: 6px;
      padding: 8px 16px; }
      .form_newsletter .btn:hover {
        padding: 10px 16px; } }

.form_quote {
  padding: 0 0 0.75rem;
  border: 1px solid #ddd;
  background-color: #fff; }
  .form_quote .form_title {
    margin: 0 0 0.5rem;
    padding: 0.75rem;
    background-color: #053891;
    color: #fff; }

.form_review .review_text {
  margin-bottom: 1em; }
  .form_review .review_text .form_control {
    height: 200px; }

.radio_group {
  margin-bottom: 1em;
  line-height: 1em; }
  .radio_group > label {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    min-width: 120px;
    vertical-align: baseline;
    top: -0.35em; }
  .radio_group .error-message {
    clear: both;
    margin-top: 0.75em; }

.rating_recommend p {
  margin: 0.4em 0 0;
  padding: 0; }

.rating_recommend .error-message {
  clear: both;
  margin-top: 0.75em; }

.rating_recommend .radio_yn,
.rating_recommend .radio_yn-label {
  box-sizing: border-box;
  display: inline-block;
  width: 70px;
  height: 36px;
  margin: 0 0 0 -5px;
  padding: 0.3em 0;
  font-size: 1.3rem;
  line-height: 1em;
  text-align: left;
  vertical-align: top;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer; }
  @media (min-width: 768px) {
    .rating_recommend .radio_yn,
    .rating_recommend .radio_yn-label {
      height: 39px; } }

.rating_recommend .radio_yn:checked + .radio_yn-label {
  background-color: #053891 !important;
  color: #fff; }

.rating_recommend .radio_yn:hover + .radio_yn-label {
  background-color: #aaa; }

.rating_recommend .radio_yn-label {
  margin-left: -70px;
  background-color: #fff;
  text-align: center; }

.rating {
  overflow: hidden;
  display: inline-block; }

.rating-input {
  float: right;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 0 0 -24px;
  opacity: 0; }

.rating-star {
  cursor: pointer;
  position: relative;
  float: right;
  display: block;
  width: 28px;
  height: 25px;
  background: url("/_/images/icons/star-rating.png") 0 -24px no-repeat; }

.rating-star:hover,
.rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
  background-position: 0 0; }

.gallery {
  margin-bottom: 1em; }

.img-captioned img {
  margin-bottom: 0.5em; }

.img-captioned span {
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em 0;
  font-size: 87.5%;
  line-height: 1.25em;
  transform: skew(-10deg); }

.gallery_sidebar img {
  margin-bottom: 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a, .g2-md > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd), .g2-md > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even), .g2-md > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a, .g2-lg > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd), .g2-lg > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even), .g2-lg > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g3-lg > img, .g3-lg > a, .g3-lg > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-lg > img:nth-child(3n-2), .g3-lg > a:nth-child(3n-2), .g3-lg > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-lg > img:nth-child(3n), .g3-lg > a:nth-child(3n), .g3-lg > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g4-lg > img, .g4-lg > a, .g4-lg > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-lg > img:nth-child(4n-3), .g4-lg > a:nth-child(4n-3), .g4-lg > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-lg > img:nth-child(4n), .g4-lg > a:nth-child(4n), .g4-lg > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a, .g3-xl > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2), .g3-xl > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n), .g3-xl > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.info_company {
  line-height: 1em;
  text-align: center; }
  .info_company .company_name {
    display: block;
    font-size: 1.2em; }
  .info_company .phone {
    font-size: 1.7em;
    line-height: 1.5em;
    font-weight: 800;
    color: #fff; }
  .info_company .company_hours {
    display: block;
    margin: 0 0 0.5em; }
  .info_company .company_address {
    margin: 0 0 0.5em; }
  .info_company .company_street {
    display: block; }
  .info_company .company_license small {
    display: block; }
  @media (min-width: 576px) {
    .info_company {
      text-align: left; } }

.info_copyright {
  padding-top: 1em;
  font-size: 0.85rem;
  line-height: 1.3em;
  text-align: center; }
  .info_copyright a {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1em;
    text-decoration: none;
    color: #fff; }
  @media (min-width: 768px) {
    .info_copyright {
      padding-top: 0;
      text-align: right; } }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 95000;
  text-align: center;
  line-height: 0;
  font-weight: 400; }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/layout/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/layout/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #fff; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.svg") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.nav_menu {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 0.75em;
  height: 0.75em;
  background-image: url("/_/images/icons/dropdown.svg");
  background-repeat: no-repeat;
  background-size: 0.85em;
  background-position: center center; }

.dropdown_menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px; }
  .dropdown_menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown_menu .menu_link {
    padding-left: 10px; }
    .dropdown_menu .menu_link:hover, .dropdown_menu .menu_link:focus {
      outline: none; }
  @media (min-width: 768px) {
    .dropdown_menu {
      position: absolute; } }

.nav_bar {
  padding: 0; }
  .nav_bar .nav_menu > .menu_item {
    text-align: center; }
  .nav_bar .nav_menu > .menu_item:not(:last-child) {
    border-right: 1px solid black; }
  .nav_bar .nav_menu > .menu_item:not(:first-child) {
    border-left: 1px solid rgba(255, 255, 255, 0.3); }
  .nav_bar .nav_menu > .menu_item > .menu_link {
    padding: 18px 15px; }
  .nav_bar.nav--primary {
    background-color: #053891; }
    .nav_bar.nav--primary .menu_link {
      color: #fff; }
      .nav_bar.nav--primary .menu_link:hover {
        background-color: #0855db; }
    .nav_bar.nav--primary .open > .menu_link {
      background-color: #053891; }
    .nav_bar.nav--primary .dropdown_menu {
      background-color: #053891; }

.mobile_button {
  padding: 0 20px;
  background: #021b47; }
  .mobile_button .mobile_toggle {
    padding: 0.75em;
    background-color: #EF4923;
    border: none;
    color: #fff; }
    .mobile_button .mobile_toggle:hover, .mobile_button .mobile_toggle:focus {
      outline: none;
      background-color: #b82d0d; }
  .mobile_button .button-bars {
    display: inline-block;
    height: 100%;
    width: 1.5em;
    margin-right: 0.25em; }
  .mobile_button .icon-bar {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff; }
    .mobile_button .icon-bar:nth-child(2) {
      margin: 0.3em 0; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #053891;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .nav_menu > .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.7); }
  .nav_mobile .nav_menu > .menu_item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.3); }
  .nav_mobile .nav_menu > .menu_item {
    font-size: 0.875em; }
    .nav_mobile .nav_menu > .menu_item.active .menu_link {
      background-color: #fff;
      color: #053891; }
      .nav_mobile .nav_menu > .menu_item.active .menu_link:hover, .nav_mobile .nav_menu > .menu_item.active .menu_link:focus {
        outline: none;
        background-color: #fff;
        color: #053891; }
    .nav_mobile .nav_menu > .menu_item.open > .menu_link {
      background-color: rgba(255, 255, 255, 0.2); }
    .nav_mobile .nav_menu > .menu_item.open .dropdown_menu {
      background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item > .menu_link {
    padding: 12px 10px;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav_menu > .menu_item > .menu_link:hover, .nav_mobile .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.2);
      color: #fff; }
  .nav_mobile .dropdown_menu .menu_item {
    font-size: 0.875em; }
  .nav_mobile .dropdown_menu .menu_link {
    background-color: transparent;
    color: #fff; }
    .nav_mobile .dropdown_menu .menu_link:hover, .nav_mobile .dropdown_menu .menu_link:focus {
      outline: none;
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

/*.navmenu {
	@include offcanvas;
	width: 290px; //$navmenu-width;
	height: 100%;
	border-width: 1px;
	border-style: solid;
	//border-radius: $border-radius-base;
}

.navmenu-fixed-left {
	position: fixed;
	z-index: 999999; //$zindex-navmenu-fixed;
	top: 0;
	left: 0;
	right: auto;
	border-width: 0 1px 0 0;
	bottom: 0;
	overflow-y: auto;
}*/
.nav_top {
  padding: 0;
  background-color: #021b47; }
  .nav_top .nav_menu {
    display: block;
    width: auto;
    float: right; }
  .nav_top .nav_menu > .menu_item {
    width: auto; }
  .nav_top .menu_item.active .menu_link {
    background-color: #fff !important;
    color: #053891 !important; }
  .nav_top .nav_menu > .menu_item > .menu_link {
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    background-color: #021b47;
    color: #fff; }
    .nav_top .nav_menu > .menu_item > .menu_link:hover, .nav_top .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: #0642aa;
      color: #fff; }

.nav_pills .nav_menu > .menu_item > .menu_link {
  border-radius: 4px; }

.nav_pills .nav_menu > .open > .menu_link {
  border-radius: 4px 4px 0 0; }

.nav_pills.nav--primary .menu_link {
  background-color: #053891;
  color: #fff; }
  .nav_pills.nav--primary .menu_link:hover {
    background-color: #0855db; }

.nav_pills.nav--primary .open > .menu_link {
  background-color: #053891; }

.nav_pills.nav--primary .dropdown_menu {
  background-color: #053891; }

.nav_pills.nav--secondary .menu_link {
  background-color: #EF4923;
  color: #fff; }
  .nav_pills.nav--secondary .menu_link:hover {
    background-color: #f4846a; }

.nav_pills.nav--secondary .open > .menu_link {
  background-color: #EF4923; }

.nav_pills.nav--secondary .dropdown_menu {
  background-color: #EF4923; }

.nav_pills.nav--highlight .menu_link {
  background-color: #5BC0DE;
  color: #fff; }
  .nav_pills.nav--highlight .menu_link:hover {
    background-color: #9bd8eb; }

.nav_pills.nav--highlight .open > .menu_link {
  background-color: #5BC0DE; }

.nav_pills.nav--highlight .dropdown_menu {
  background-color: #5BC0DE; }

.nav_pills.nav--accent .menu_link {
  background-color: #efdc09;
  color: #fff; }
  .nav_pills.nav--accent .menu_link:hover {
    background-color: #f8ea4c; }

.nav_pills.nav--accent .open > .menu_link {
  background-color: #efdc09; }

.nav_pills.nav--accent .dropdown_menu {
  background-color: #efdc09; }

.coverage {
  padding: 30px; }
  .coverage .coverage_title {
    margin: 0 0 0.75rem;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase; }
  .coverage p {
    margin-bottom: 0.5rem;
    line-height: 1.4em; }
    .coverage p:last-of-type {
      margin-bottom: 0; }
  .coverage .coverage_county {
    font-size: 0.95rem;
    font-weight: 600; }
  .coverage .coverage_city {
    font-size: 0.9rem; }

.page-title {
  position: relative;
  z-index: 70000;
  padding: 2em 0;
  color: #fff;
  border-bottom: 5px solid #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  .page-title h1 {
    margin: 0;
    line-height: 1em; }
  @media (min-width: 576px) {
    .page-title {
      padding: 4em 0 1em; } }
  @media (min-width: 768px) {
    .page-title {
      padding-top: 5em; } }
  @media (min-width: 992px) {
    .page-title {
      padding-top: 7em; } }
  @media (min-width: 1200px) {
    .page-title {
      padding-top: 9em; } }
  .post .page-title h1 {
    max-width: 730px; }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin: 0 0 15px;
  padding: 15px;
  font-size: 85%;
  border: 1px solid #ddd;
  background-color: #fff; }
  .widget > *:last-child {
    margin-bottom: 0; }
  .widget.widget-default .widget-title {
    color: #333; }
  .widget.widget-text {
    border-color: #333; }
    .widget.widget-text .widget-title {
      background-color: #333; }
  .widget.widget-primary {
    border-color: #053891; }
    .widget.widget-primary .widget-title {
      background-color: #053891; }
  .widget.widget-secondary {
    border-color: #EF4923; }
    .widget.widget-secondary .widget-title {
      background-color: #EF4923; }
  .widget.widget-highlight {
    border-color: #5BC0DE; }
    .widget.widget-highlight .widget-title {
      background-color: #5BC0DE; }
  .widget.widget-link {
    border-color: #17a2b8; }
    .widget.widget-link .widget-title {
      background-color: #17a2b8; }
  .widget.widget-review {
    border-color: #fd7e14; }
    .widget.widget-review .widget-title {
      background-color: #fd7e14; }
  .widget.widget-success {
    border-color: #28a745; }
    .widget.widget-success .widget-title {
      background-color: #28a745; }
  .widget.widget-danger {
    border-color: #dc3545; }
    .widget.widget-danger .widget-title {
      background-color: #dc3545; }
  .widget.widget-info {
    border-color: #6610f2; }
    .widget.widget-info .widget-title {
      background-color: #6610f2; }
  .widget.widget-warning {
    border-color: #ffc107; }
    .widget.widget-warning .widget-title {
      background-color: #ffc107; }

.widget-title:not(.plain) {
  margin: -15px -15px 15px;
  padding: 12px 15px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ddd;
  color: #fff; }

.widget-title.plain {
  margin: 0 0 5px; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }
  .widget_plain .widget-title {
    margin: 0 0 15px;
    padding: 0px;
    background-color: transparent;
    color: inherit; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget-rwreviews {
    overflow: hidden; }
    .widget-rwreviews p {
      float: left;
      width: 49%; }
      .widget-rwreviews p:nth-child(odd) {
        clear: both; }
      .widget-rwreviews p:nth-child(even) {
        float: right; } }

.site_info {
  margin-top: 1em;
  padding: 2.25em 0 2em;
  border-top: 5px solid #fff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #053891;
  color: #fff;
  font-size: 0.9em; }

.about_city {
  margin-bottom: 1em;
  padding: 15px;
  font-size: 85%;
  line-height: 1.4em;
  border: 1px solid #ccc;
  border-left: 10px solid #333;
  background-color: rgba(255, 255, 255, 0.5); }
  .about_city .city_title {
    margin-top: 15px;
    font-weight: 800; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }

.block-main {
  padding: 1.5em 0;
  clear: both; }
  .block-main .block_title {
    margin-top: 0; }

.choose {
  border-top: 0.25rem solid #fff;
  border-bottom: 0.25rem solid #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  color: #fff; }
  .choose .block_title:before {
    content: "Why Choose "; }
  .choose .block_title:after {
    content: "?"; }
  .choose .img_satisfaction {
    width: 90%;
    max-width: 240px;
    margin-bottom: 1rem; }
  .choose ul {
    line-height: 1em; }
    .choose ul li:not(:last-child) {
      margin-bottom: 0.75rem; }
  @media (min-width: 992px) {
    .choose .block_title {
      margin-bottom: 2.5rem; }
    .choose ul {
      overflow: hidden; }
      .choose ul li {
        float: left;
        width: 45%; }
        .choose ul li:not(:last-child) {
          margin-bottom: 1rem; }
        .choose ul li:nth-child(odd) {
          width: 55%; }
    .choose .img_satisfaction {
      margin: -80px 0 0; } }
  @media (min-width: 1200px) {
    .choose ul > li {
      width: 50%; }
      .choose ul > li:nth-child(odd) {
        width: 50%; }
    .choose .img_satisfaction {
      margin-right: 100px; } }

.jumbo {
  position: relative;
  padding: 0;
  background-color: #5BC0DE; }
  .jumbo .carousel {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    border-bottom: 5px solid #fff; }
  .jumbo .item {
    overflow: hidden; }
    .jumbo .item img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom; }
  @media (min-width: 992px) {
    .jumbo {
      background-color: transparent; }
      .jumbo .carousel {
        border: none; }
      .jumbo .item {
        height: 460px; } }
  @media (min-width: 1399px) {
    .jumbo .carousel-inner {
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.8); } }

.jumbo .carousel-caption {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  background-color: #053891;
  color: #fff; }
  .jumbo .carousel-caption br {
    display: none; }
  @media (min-width: 768px) {
    .jumbo .carousel-caption {
      position: absolute;
      z-index: 10;
      bottom: 0;
      width: 100%;
      background-color: rgba(2, 27, 71, 0.7);
      color: #fff;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7); }
      .jumbo .carousel-caption .caption_title {
        font-size: 1.6rem;
        letter-spacing: -0.025em; }
      .jumbo .carousel-caption .caption_text {
        font-size: 1rem; } }
  @media (min-width: 992px) {
    .jumbo .carousel-caption {
      right: -60px;
      top: 38.5%;
      bottom: auto;
      transform: skew(-20deg);
      width: auto;
      padding: 1em 100px 1em 2em;
      text-align: right;
      background-color: rgba(239, 73, 35, 0.7); }
      .jumbo .carousel-caption br {
        display: block; }
      .jumbo .carousel-caption .caption_title {
        transform: skew(5deg);
        font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .jumbo .carousel-caption {
      padding-right: 10%; } }
  @media (min-width: 1300px) {
    .jumbo .carousel-caption {
      padding-right: 12.5%; } }
  @media (min-width: 1400px) {
    .jumbo .carousel-caption {
      padding-right: 15%; } }

.item_01 img {
  background-image: url("/_/images/slides/01-sm.jpg"); }
  @media (min-width: 768px) {
    .item_01 img {
      background-image: url("/_/images/slides/01-md.jpg"); } }
  @media (min-width: 992px) {
    .item_01 img {
      background-image: url("/_/images/slides/01-lg.jpg"); } }

.item_02 img {
  background-image: url("/_/images/slides/02-sm.jpg"); }
  @media (min-width: 768px) {
    .item_02 img {
      background-image: url("/_/images/slides/02-md.jpg"); } }
  @media (min-width: 992px) {
    .item_02 img {
      background-image: url("/_/images/slides/02-lg.jpg"); } }

.item_03 img {
  background-image: url("/_/images/slides/03-sm.jpg"); }
  @media (min-width: 768px) {
    .item_03 img {
      background-image: url("/_/images/slides/03-md.jpg"); } }
  @media (min-width: 992px) {
    .item_03 img {
      background-image: url("/_/images/slides/03-lg.jpg"); } }

.item_04 img {
  background-image: url("/_/images/slides/04-sm.jpg"); }
  @media (min-width: 768px) {
    .item_04 img {
      background-image: url("/_/images/slides/04-md.jpg"); } }
  @media (min-width: 992px) {
    .item_04 img {
      background-image: url("/_/images/slides/04-lg.jpg"); } }

.item_05 img {
  background-image: url("/_/images/slides/05-sm.jpg"); }
  @media (min-width: 768px) {
    .item_05 img {
      background-image: url("/_/images/slides/05-md.jpg"); } }
  @media (min-width: 992px) {
    .item_05 img {
      background-image: url("/_/images/slides/05-lg.jpg"); } }

.item_06 img {
  background-image: url("/_/images/slides/06-sm.jpg"); }
  @media (min-width: 768px) {
    .item_06 img {
      background-image: url("/_/images/slides/06-md.jpg"); } }
  @media (min-width: 992px) {
    .item_06 img {
      background-image: url("/_/images/slides/06-lg.jpg"); } }

.item_07 img {
  background-image: url("/_/images/slides/07-sm.jpg"); }
  @media (min-width: 768px) {
    .item_07 img {
      background-image: url("/_/images/slides/07-md.jpg"); } }
  @media (min-width: 992px) {
    .item_07 img {
      background-image: url("/_/images/slides/07-lg.jpg"); } }

.item_08 img {
  background-image: url("/_/images/slides/08-sm.jpg"); }
  @media (min-width: 768px) {
    .item_08 img {
      background-image: url("/_/images/slides/08-md.jpg"); } }
  @media (min-width: 992px) {
    .item_08 img {
      background-image: url("/_/images/slides/08-lg.jpg"); } }

.item_09 img {
  background-image: url("/_/images/slides/09-sm.jpg"); }
  @media (min-width: 768px) {
    .item_09 img {
      background-image: url("/_/images/slides/09-md.jpg"); } }
  @media (min-width: 992px) {
    .item_09 img {
      background-image: url("/_/images/slides/09-lg.jpg"); } }

.item_10 img {
  background-image: url("/_/images/slides/10-sm.jpg"); }
  @media (min-width: 768px) {
    .item_10 img {
      background-image: url("/_/images/slides/10-md.jpg"); } }
  @media (min-width: 992px) {
    .item_10 img {
      background-image: url("/_/images/slides/10-lg.jpg"); } }

.jumbo_conversion {
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1.1rem;
  line-height: 1em;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  color: #fff; }
  .jumbo_conversion .conversion_title {
    font-weight: 800;
    line-height: 1em;
    text-transform: uppercase; }
    .jumbo_conversion .conversion_title small {
      display: block; }
  .jumbo_conversion .phone {
    font-size: 1.8rem;
    font-weight: 800;
    line-height: 1em;
    color: #fff; }
  .jumbo_conversion .btn {
    display: inline-block;
    padding: 0.5em 1em;
    text-decoration: none;
    border: 1px solid #042a6c;
    background-color: #063fa3;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    padding: 1em;
    font-size: 0.9rem;
    font-weight: 800;
    text-transform: uppercase; }
    .jumbo_conversion .btn:hover {
      background-color: #1265f7;
      color: #fff;
      -webkit-transition: all ease-in-out 200ms;
      -moz-transition: all ease-in-out 200ms;
      -ms-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .jumbo_conversion .conversion_button {
    margin-bottom: 0;
    white-space: nowrap; }
    .jumbo_conversion .conversion_button img {
      position: relative;
      vertical-align: top;
      display: inline-block;
      width: 2em; }
  @media (min-width: 992px) {
    .jumbo_conversion {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
      margin-top: -370px; }
      .jumbo_conversion .conversion_wrapper {
        padding: 2em 1em;
        background-color: rgba(40, 161, 197, 0.9); }
      .jumbo_conversion p {
        margin-bottom: 0.5em; }
      .jumbo_conversion .conversion_title {
        font-size: 1.3rem; }
      .jumbo_conversion .phone {
        font-size: 2.3rem; }
      .jumbo_conversion p.conversion_button {
        margin: 1em 0 0; } }
  @media (min-width: 1200px) {
    .jumbo_conversion {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.home-reviews {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #fd7e14; }

.home-reviews {
  text-align: center;
  border-bottom: 5px solid #fff; }
  .home-reviews .block__title {
    margin-top: 0;
    margin-bottom: 1em;
    font-weight: 800;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    color: #fff; }
  .home-reviews a {
    font-weight: 800; }
  @media (min-width: 576px) {
    .home-reviews a {
      font-size: ms(3); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .home-reviews .review:nth-child(1), .home-reviews .review:nth-child(2) {
      float: left;
      width: 48.5%; }
    .home-reviews .review:nth-child(2) {
      float: right; }
    .home-reviews .review:nth-child(3) {
      clear: both; } }
  @media (min-width: 992px) {
    .home-reviews {
      padding: 2em 0; }
      .home-reviews .review {
        float: left;
        width: 32%;
        margin-right: 2%; }
        .home-reviews .review:nth-child(3) {
          margin-right: 0; } }

.review {
  position: relative;
  margin-bottom: 2em;
  padding: 2em 1em;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1.5em 1em -1em rgba(0, 0, 0, 0.3); }
  .review strong {
    display: block;
    font-size: 115%;
    line-height: 1.1em;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .review .review__stars {
    max-width: 150px;
    margin: 0.5em auto; }
  .review:after {
    content: "";
    position: absolute;
    bottom: -1em;
    left: 45.25%;
    border-width: 1em 1.5em 0;
    border-style: solid;
    border-color: #fff transparent;
    display: block;
    width: 0; }

.block-main.socials {
  position: relative;
  z-index: 5000;
  padding: 30px 0 10px !important;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff; }

.awards_list {
  text-align: center; }
  .awards_list li:not(:last-child) {
    margin-bottom: 1em; }
  .awards_list a {
    color: #333;
    font-size: 14px;
    line-height: 1.1em;
    text-decoration: none; }
    .awards_list a strong {
      display: block; }
    .awards_list a:hover strong {
      color: #EF4923; }
  .awards_list img {
    max-width: 125px;
    margin: 0 auto 0.25em; }
  @media (min-width: 576px) and (max-width: 767px) {
    .awards_list {
      overflow: hidden; }
      .awards_list li {
        display: block;
        float: left;
        width: 50%; } }
  @media (min-width: 768px) {
    .awards_list li {
      display: block;
      float: left;
      width: 25%; }
      .awards_list li img {
        max-width: 100px; } }
